import React from "react"
import { Link } from "gatsby"
import Drawer from "@material-ui/core/Drawer"
import gsap from "gsap"
import Logo from "./Logo"
import SocialButtons from "./SocialButtons"

const btnSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M562.1 383.9c-21.5-2.4-42.1-10.5-57.9-22.9-14.1-11.1-34.2-11.3-48.2 0-37.9 30.4-107.2 30.4-145.7-1.5-13.5-11.2-33-9.1-46.7 1.8-38 30.1-106.9 30-145.2-1.7-13.5-11.2-33.3-8.9-47.1 2-15.5 12.2-36 20.1-57.7 22.4-7.9.8-13.6 7.8-13.6 15.7v32.2c0 9.1 7.6 16.8 16.7 16 28.8-2.5 56.1-11.4 79.4-25.9 56.5 34.6 137 34.1 192 0 56.5 34.6 137 34.1 192 0 23.3 14.2 50.9 23.3 79.1 25.8 9.1.8 16.7-6.9 16.7-16v-31.6c.1-8-5.7-15.4-13.8-16.3zm0-144c-21.5-2.4-42.1-10.5-57.9-22.9-14.1-11.1-34.2-11.3-48.2 0-37.9 30.4-107.2 30.4-145.7-1.5-13.5-11.2-33-9.1-46.7 1.8-38 30.1-106.9 30-145.2-1.7-13.5-11.2-33.3-8.9-47.1 2-15.5 12.2-36 20.1-57.7 22.4-7.9.8-13.6 7.8-13.6 15.7v32.2c0 9.1 7.6 16.8 16.7 16 28.8-2.5 56.1-11.4 79.4-25.9 56.5 34.6 137 34.1 192 0 56.5 34.6 137 34.1 192 0 23.3 14.2 50.9 23.3 79.1 25.8 9.1.8 16.7-6.9 16.7-16v-31.6c.1-8-5.7-15.4-13.8-16.3zm0-144C540.6 93.4 520 85.4 504.2 73 490.1 61.9 470 61.7 456 73c-37.9 30.4-107.2 30.4-145.7-1.5-13.5-11.2-33-9.1-46.7 1.8-38 30.1-106.9 30-145.2-1.7-13.5-11.2-33.3-8.9-47.1 2-15.5 12.2-36 20.1-57.7 22.4-7.9.8-13.6 7.8-13.6 15.7v32.2c0 9.1 7.6 16.8 16.7 16 28.8-2.5 56.1-11.4 79.4-25.9 56.5 34.6 137 34.1 192 0 56.5 34.6 137 34.1 192 0 23.3 14.2 50.9 23.3 79.1 25.8 9.1.8 16.7-6.9 16.7-16v-31.6c.1-8-5.7-15.4-13.8-16.3z"
    />
  </svg>
)

const MenuDrawer: React.FC<{ menuItems: string[] }> = ({ menuItems }) => {
  const [open, setOpen] = React.useState(false)
  const svgRef = React.useRef<SVGSVGElement>(null)
  const listRef = React.useRef<HTMLUListElement>(null)
  const logoRef = React.useRef<HTMLDivElement>(null)
  const btnRef = React.useRef<HTMLDivElement>(null)
  const timeline = React.useCallback(() => {
    const tl = gsap.timeline({ paused: true })
    if (svgRef.current && listRef.current) {
      tl.fromTo(
        svgRef.current,
        {
          width: 0,
          duration: 0.8,
          ease: "slow",
        },
        {
          width: 90,
          duration: 0.8,
          ease: "slow",
        },
        0
      )
      tl.fromTo(
        logoRef.current,
        {
          opacity: 0,
          translateX: 24,
          duration: 0.5,
        },
        {
          opacity: 1,
          translateX: 0,
          duration: 0.5,
        },
        0
      )
      listRef.current.childNodes.forEach((node, index) => {
        tl.fromTo(
          node,
          {
            opacity: 0,
            translateX: 24,
            delay: (index + 1) * 0.1,
            duration: 0.5,
          },
          {
            opacity: 1,
            translateX: 0,
            delay: (index + 1) * 0.1,
            duration: 0.5,
          },
          0
        )
        tl.fromTo(
          node.lastChild,
          {
            scaleX: 0,
            ease: "power4",
          },
          {
            scaleX: 1,
            ease: "power4",
          },
          1
        )
      })
      tl.fromTo(
        btnRef.current,
        {
          opacity: 0,
          translateY: 12,
        },
        {
          opacity: 1,
          translateY: 0,
        },
        1
      )
    }
    return tl
  }, [])

  function handleClose() {
    setOpen(false)
  }
  function handleOpen() {
    setOpen(true)
    timeline().restart()
  }
  return (
    <>
      <button className="menu-btn" onClick={handleOpen}>
        {btnSVG}
      </button>
      <Drawer open={open} anchor="right" onClose={handleClose} keepMounted>
        <div className="side-nav">
          <div
            className="svg-container"
            onClick={handleClose}
            role="button"
            tabIndex={-1}
          >
            <svg ref={svgRef} viewBox="0 0 75 500" preserveAspectRatio="none">
              <path
                fill="currentColor"
                d="M241.49 0C151.05 0 94.53 0 71.93 0C66.41 0 61.07 1.93 56.83 5.47C48.77 12.19 57.05 5.28 55.47 6.6C31.85 26.29 22.25 58.17 31.09 87.63C37.55 109.17 28.42 78.74 31.4 88.66C52.14 157.81 49.39 231.88 23.58 299.3C16.41 318.02 30.07 282.35 21.74 304.11C-0.35 361.81 -3.62 425.02 12.41 484.69C12.96 486.73 14.33 491.84 16.52 500L241.49 500L241.49 0Z"
              />
            </svg>
          </div>
          <div className="content-container">
            <div className="header">
              <div ref={logoRef}>
                <Link className="logo" to="/" onClick={handleClose}>
                  <Logo />
                </Link>
              </div>
            </div>
            <ul className="nav-list side-nav" ref={listRef}>
              {menuItems.map((item, index) => {
                return (
                  <li key={item}>
                    <Link to={`/#${item}`} onClick={handleClose}>
                      {item}
                    </Link>
                    <span
                      className={index + 1 === menuItems.length ? " hide" : ""}
                    />
                  </li>
                )
              })}
            </ul>
            <div
              className="social-btns-container flex-center-center"
              ref={btnRef}
            >
              <SocialButtons />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default MenuDrawer
