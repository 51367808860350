import React from "react"
import { Helmet } from "react-helmet"
import "../stylesheets/index.sass"

const URL_PREFIX = "https://wavefoundry.io";

const Layout: React.FC<{ title: string; description: string }> = ({
  children,
  title,
  description,
}) => {
  const calculatedTitle = `Wavefoundry - ${title}`
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{calculatedTitle}</title>
        <link
          rel="mask-icon"
          href={`${URL_PREFIX}/safari-pinned-tab.svg`}
          color="#039be5"
        />
        <meta name="description" content={description} />
        <meta name="theme-color" content="#039be5" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={calculatedTitle} />
        <meta property="og:url" content={URL_PREFIX} />
        <meta property="og:image" content={`${URL_PREFIX}/og-image.png`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={calculatedTitle} />
        <meta property="twitter:description" content={description} />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-86MSGKY63F"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-86MSGKY63F');`}
        </script>
      </Helmet>
      <main>{children}</main>
    </>
  )
}

export default Layout
