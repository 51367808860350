import React from "react"
import Icon from "./Icon"

export default () => {
  return (
    <>
      <a
        className="flex-center-center"
        href="https://www.linkedin.com/company/wavefoundry/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon iconName="linkedIn" width={20} />
      </a>
      <a
        className="flex-center-center"
        href="https://www.facebook.com/wavefoundry/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon iconName="facebook" width={20} />
      </a>
      <a
        className="flex-center-center"
        href="mailto:hello@wavefoundry.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon iconName="email" width={20} />
      </a>
    </>
  )
}
