import React from "react"
import { Link } from "gatsby"
import Logo from "./Logo"
import MenuDrawer from "./MenuDrawer"
import NavLink from "./NavLink"

const menuItems = ["services", "about", "showcase", "contact"]

const Navbar: React.FC = () => {
  return (
    <nav>
      <div className="layout-container nav-container">
        <div>
          <Link to="/" className="logo">
            <Logo />
          </Link>
        </div>
        <div>
          <ul className="nav-list">
            {menuItems.map(item => {
              return (
                <li key={item}>
                  <NavLink item={item} />
                </li>
              )
            })}
          </ul>
        </div>
        <MenuDrawer menuItems={menuItems} />
      </div>
    </nav>
  )
}

export default Navbar
