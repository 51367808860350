import React from "react"
import { Link } from "gatsby"
import gsap, { Sine } from "gsap"
import { lightBlue } from "@material-ui/core/colors"

const NavLink: React.FC<{ item: string }> = ({ item }) => {
  const linkRef = React.useRef<any>(null)
  const tl1 = gsap.timeline({ paused: true, yoyo: true })
  React.useEffect(() => {
    if (linkRef.current) {
      const splitText = linkRef.current.childNodes
      const amplitude = 2
      const time = 0.4
      splitText.forEach(function (letter: string, i: number) {
        const delay = (i / item.length) * time
        tl1.fromTo(
          letter,
          time / 2,
          {
            y: amplitude,
            color: "#ffffff",
          },
          {
            y: -amplitude,
            color: lightBlue[50],
            ease: Sine.easeInOut,
            repeat: 1,
            yoyo: true,
            onComplete:
              i + 1 === item.length
                ? () => {
                    tl1.seek(0)
                    tl1.pause()
                  }
                : undefined,
          },
          delay
        )
      })
    }
  }, [linkRef])

  function handleMouseEnter() {
    tl1.play()
  }
  return (
    <Link
      to={`/#${item}`}
      ref={linkRef}
      onMouseEnter={handleMouseEnter}
      className="display-block"
    >
      {item
        .toUpperCase()
        .split("")
        .map((letter, index) => {
          return (
            <span
              key={`letter-${index}`}
              style={{ animationDelay: `${index * 50}ms` }}
            >
              {letter}
            </span>
          )
        })}
    </Link>
  )
}

export default NavLink
